/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavbarMenu from '../../Components/NavbarMenu';
import GenericSubBanner from '../../Components/GenericSubBanner';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

import { log } from 'console';
import { DatesSetArg, EventClickArg } from '@fullcalendar/core';
import { GraphQLBodyType } from '../../api/types';
import {
  ClassesByDateRange,
  getClasses,
  getClassesResponse,
} from '../../api/classes';
import { useLoading } from '../../context/loadingContext';
import { enqueueSnackbar } from 'notistack';
import SessionDesc from './sessionDesc';
import useWindowDimensions from '../../hooks/useDimensions';

type classesType = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  classNames: string;
}[];
const Sessions = () => {
  const [events, setEvents] = useState<classesType>([]);
  const [classes, setClasses] = useState<ClassesByDateRange[]>([]);
  const { height, width } = useWindowDimensions();

  const [active, setActive] = useState<{ title: string; description: string }>({
    title: '',
    description: '',
  });
  const { setLoading } = useLoading();

  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const handleOpen = () => setDetailOpen(true);
  const handleClose = () => setDetailOpen(false);

  const handleEventClick = (clickInfo: EventClickArg) => {
    const classDetail = classes.find((e: ClassesByDateRange) => {
      return e.id === clickInfo.event.id;
    });
    if (classDetail) {
      setActive({
        title: classDetail?.title,
        description: classDetail?.description,
      });
      handleOpen();
    }
  };

  const callDateChange = async (arg: DatesSetArg) => {
    let startDate = arg.startStr.split('+');
    let endDate = arg.endStr.split('+');
    await getCalendarData(startDate[0], endDate[0]);
  };

  const getCalendarData = async (startDate: string, endDate: string) => {
    setLoading(true);
    try {
      const bodyQuery: GraphQLBodyType = {
        query: `query {
          classesByDateRange(startDate: "${startDate}", endDate: "${endDate}") {
            id
            title
            description
            start_date
            end_date
            created_at
          }
        }`,
      };
      const classResponse: getClassesResponse = await getClasses(bodyQuery);

      if (classResponse && classResponse.data.classesByDateRange.length > 0) {
        const classes: classesType = [];
        setClasses(classResponse.data.classesByDateRange);
        classResponse.data.classesByDateRange.forEach((session) => {
          classes.push({
            id: session.id,
            title: session.title,
            start: session.start_date,
            end: session.end_date,
            allDay: false,
            classNames: 'sessionPill',
          });
        });
        console.log(classes);
        setEvents(classes);
      }
    } catch (error) {
      enqueueSnackbar('No Classes in this date range', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='sub-banner-section'>
        <header>
          <NavbarMenu />
        </header>
        {/* SUB BANNER SECTION */}
        <GenericSubBanner
          title='Sessions'
          subtitle='Explore our flexible class schedule, empowering balance, strength, and serenity for all levels.'
          pageName='sessions'
        />
      </div>

      {/* DISCOUNT SECTION */}
      <section className='discount_section '>
        <div className='container'>
          <div className='row' data-aos='fade-up'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='discount_content'>
                {/* <h2>
                  View Upcoming <span>Class</span> Schedules
                </h2> */}

                <h2>
                  SESSION TIMING - <span>STUDIO</span>
                </h2>

                <div className=''>
                  <h4>Monday - Saturday</h4>
                </div>
                <h5>
                  <span>Morning:</span> 5:30AM - 10:30 AM
                </h5>
                <h5>
                  <span>Evening:</span> 4 PM - 8:30 PM
                </h5>

                <div className='aboutpage_aboutus_content'></div>
                <h2 className=''>
                  SESSION TIMING - <span>ONLINE</span>
                </h2>
                <div className=''>
                  <h4>Monday - Saturday</h4>
                </div>
                <h5>
                  <span>Morning:</span> 5:30AM - 10:30 AM
                </h5>
                <h5>
                  <span>Evening:</span> 4 PM - 8:30 PM
                </h5>

                {/* <FullCalendar
                  // timeZone='UTC'
                  locale='en'
                  themeSystem='Simplex'
                  // datesRender={(arg) => getCurrentMonth(arg)}
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  dayMaxEvents={3}
                  moreLinkContent={(args) => {
                    return `${args.num} more sessions.`;
                  }}
                  datesSet={(arg) => {
                    void callDateChange(arg);
                  }}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                  }}
                  initialView={width < 700 ? 'timeGridWeek' : 'dayGridMonth'}
                  events={events}
                  eventClick={handleEventClick}
                /> */}
                <br></br>
                <img
                  className={'w-100'}
                  src='./assets/images/schedule.jpeg'
                ></img>
                <br></br>
                <br></br>
                <p>
                  Our session timings are flexible to suit your need. Reach out
                  to us if the above timing does not suit your convenience. We
                  are happy to discuss.
                </p>
                <p>
                  Note: Schedule are subject to change. Contact us for more info
                </p>

                <div className='btn_wrapper mt-4'>
                  <Link
                    to='/contact-us'
                    className='text-decoration-none get_started'
                  >
                    Any Doubts? Contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SessionDesc
        open={detailOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        description={active.description}
        title={active.title}
      />
    </>
  );
};

export default Sessions;
