import React from 'react';

const ComingSoon = () => {
  return (
    <>
      <nav style={{ paddingTop: 20, paddingLeft: 20 }}>
        <div className='logo'>
          {/* <img height=25px src=/nomor_logo.svg alt=logo> */}
          <svg
            height={25}
            viewBox='0 0 311 64'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_4745_20)'>
              <rect width={311} height='63.6136' fill='white' />
              <path
                d='M0.193058 62.6504H11.6839V19.3062L39.1437 62.6504H51.395V0.971499H39.9041V42.9638L13.2048 0.971499H0.193058V62.6504ZM128.66 62.6504H140.151V19.8131L158.654 44.9071L177.581 19.3907V62.6504H189.071V0.971499H178.172L158.739 26.9104L140.066 0.971499H128.66V62.6504ZM227.587 63.4953C245.161 63.4953 259.187 49.3852 259.187 31.8109C259.187 14.3212 245.161 0.126583 227.587 0.126583C210.013 0.126583 196.072 14.3212 196.072 31.8109C196.072 49.3852 210.013 63.4953 227.587 63.4953ZM227.587 52.1734C216.35 52.1734 207.478 43.0483 207.478 31.8109C207.478 20.5736 216.35 11.4485 227.587 11.4485C238.824 11.4485 247.781 20.5736 247.781 31.8109C247.781 43.0483 238.824 52.1734 227.587 52.1734ZM277.694 62.6504V43.8088H288.002L297.887 62.6504H310.984L298.648 40.4291C305.407 36.7959 309.04 29.8676 309.04 22.4324C309.04 9.67414 300.507 0.971499 285.974 0.971499H266.203V62.6504H277.694ZM277.694 33.2473V11.7019H284.96C293.747 11.7019 297.549 15.673 297.549 22.0099C297.549 28.0933 294.423 33.2473 284.96 33.2473H277.694Z'
                fill='black'
              />
              <path
                d='M90.044 63.4953C107.618 63.4953 121.644 49.3852 121.644 31.8109C121.644 14.3212 107.618 0.126583 90.044 0.126583C72.4697 0.126583 58.5286 14.3212 58.5286 31.8109C58.5286 49.3852 72.4697 63.4953 90.044 63.4953ZM90.044 52.1734C78.8066 52.1734 69.935 43.0483 69.935 31.8109C69.935 20.5736 78.8066 11.4485 90.044 11.4485C101.281 11.4485 110.237 20.5736 110.237 31.8109C110.237 43.0483 101.281 52.1734 90.044 52.1734Z'
                fill='white'
              />
              <path
                d='M90.7062 89.9894C108.28 89.9894 122.306 75.8793 122.306 58.3051C122.306 40.8153 108.28 26.6207 90.7062 26.6207C73.132 26.6207 59.1908 40.8153 59.1908 58.3051C59.1908 75.8793 73.132 89.9894 90.7062 89.9894ZM90.7062 78.6676C79.4688 78.6676 70.5972 69.5425 70.5972 58.3051C70.5972 47.0677 79.4688 37.9426 90.7062 37.9426C101.944 37.9426 110.9 47.0677 110.9 58.3051C110.9 69.5425 101.944 78.6676 90.7062 78.6676Z'
                fill='black'
              />
              <path
                d='M90.7062 22.2844C108.28 22.2844 122.306 8.17427 122.306 -9.39999C122.306 -26.8898 108.28 -41.0844 90.7062 -41.0844C73.132 -41.0844 59.1908 -26.8898 59.1908 -9.39999C59.1908 8.17427 73.132 22.2844 90.7062 22.2844ZM90.7062 10.9625C79.4688 10.9625 70.5972 1.83739 70.5972 -9.39999C70.5972 -20.6374 79.4688 -29.7625 90.7062 -29.7625C101.944 -29.7625 110.9 -20.6374 110.9 -9.39999C110.9 1.83739 101.944 10.9625 90.7062 10.9625Z'
                fill='black'
              />
            </g>
            <defs>
              <clipPath id='clip0_4745_20'>
                <rect width={311} height='63.6136' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </div>
      </nav>
      <div className='center'>
        <h1 style={{ fontWeight: 700, textAlign: 'center' }}>
          Get Ready To Say 'Nomor' To The Traditional Shopping
        </h1>
        <p>Something Exciting is coming Soon</p>
        <div className='social'>
          <a
            style={{ fontWeight: 700, textDecoration: 'none', color: '#000' }}
            href='https://www.facebook.com/yogarelive'
            target='_blank'
          >
            Facebook
          </a>
          <a
            style={{ fontWeight: 700, textDecoration: 'none', color: '#000' }}
            href='https://www.instagram.com/reliveyog/'
            target='_blank'
          >
            Instagram
          </a>
        </div>
      </div>
      <div
        className='tagline'
        style={{ position: 'absolute', bottom: 0, left: 0, paddingLeft: 20 }}
      >
        <p style={{ fontSize: '1em', fontWeight: 600 }}>
          No More Shopping Stress With Nomor
        </p>
      </div>
      <div
        className='copyright'
        style={{ position: 'absolute', bottom: 0, right: 0, paddingRight: 20 }}
      >
        <p style={{ fontSize: '.8em', fontWeight: 700 }}>
          © 2023 Nomor Pvt. Ltd.
        </p>
      </div>
    </>
  );
};

export default ComingSoon;
