import { Image } from 'react-grid-gallery';

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: './assets/images/image_01.jpeg',
    original: './assets/images/image_01.jpeg',
    width: 900,
    height: 450,
    tags: [],
    caption: '',
  },
  {
    src: './assets/images/image_02.jpeg',
    original: './assets/images/image_02.jpeg',
    width: 900,
    height: 450,
    tags: [],
    caption: '',
  },
  {
    src: './assets/images/image_05.jpeg',
    original: './assets/images/image_05.jpeg',
    width: 500,
    height: 450,
    tags: [],
    caption: '',
  },
  {
    src: './assets/images/image_03.jpeg',
    original: './assets/images/image_03.jpeg',
    width: 500,
    height: 450,
    tags: [],
    caption: '',
  },
  {
    src: './assets/images/image_04.jpeg',
    original: './assets/images/image_04.jpeg',
    width: 700,
    height: 450,
    tags: [],
    caption: '',
  },

  {
    src: './assets/images/image_06.jpeg',
    original: './assets/images/image_06.jpeg',
    width: 900,
    height: 450,
    tags: [],
    caption: '',
  },
  {
    src: './assets/images/image_07.jpeg',
    original: './assets/images/image_07.jpeg',
    width: 700,
    height: 450,
    tags: [],
    caption: '',
  },
  {
    src: './assets/images/image_08.jpeg',
    original: './assets/images/image_08.jpeg',
    width: 500,
    height: 450,
    tags: [],
    caption: '',
  },
  {
    src: './assets/images/gallery1.jpg',
    original: './assets/images/gallery1.jpg',
    width: 800,
    height: 450,
    tags: [
      // { value: 'Nature', title: 'Nature' },
      // { value: 'Flora', title: 'Flora' },
    ],
    caption: '',
  },
  {
    src: './assets/images/gallery2.jpg',
    original: './assets/images/gallery2.jpg',
    width: 850,
    height: 450,
    tags: [
      // { value: 'Nature', title: 'Nature' },
      // { value: 'Flora', title: 'Flora' },
    ],
    caption: '',
  },
  {
    src: './assets/images/gallery3.jpg',
    original: './assets/images/gallery3.jpg',
    width: 750,
    height: 450,
    tags: [
      // { value: 'Nature', title: 'Nature' },
      // { value: 'Flora', title: 'Flora' },
    ],
    caption: '',
  },
  {
    src: './assets/images/gallery4.jpg',
    original: './assets/images/gallery4.jpg',
    width: 500,
    height: 450,
    tags: [
      // { value: 'Nature', title: 'Nature' },
      // { value: 'Flora', title: 'Flora' },
    ],
    caption: '',
  },
  {
    src: './assets/images/gallery5.jpg',
    original: './assets/images/gallery5.jpg',
    width: 750,
    height: 450,
    tags: [
      // { value: 'Nature', title: 'Nature' },
      // { value: 'Flora', title: 'Flora' },
    ],
    caption: '',
  },
  {
    src: './assets/images/gallery6.jpg',
    original: './assets/images/gallery6.jpg',
    width: 700,
    height: 450,
    tags: [
      // { value: 'Nature', title: 'Nature' },
      // { value: 'Flora', title: 'Flora' },
    ],
    caption: '',
  },
];
