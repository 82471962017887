import React from 'react';
import NavbarMenu from '../../Components/NavbarMenu';
import GenericSubBanner from '../../Components/GenericSubBanner';
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';

const ContactUs = () => {
  return (
    <>
      <div className='sub-banner-section'>
        <header>
          <NavbarMenu />
        </header>
        {/* SUB BANNER SECTION */}
        <GenericSubBanner
          title='Contact Us'
          subtitle='Reach Out and Embrace Your Inner Serenity'
          pageName='contact-us'
        />
      </div>

      {/* MESSAGE SECTION */}
      <section className='message_section'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-8 col-md-8 col-sm-12 col-xs-12'
              data-aos='fade-up'
            >
              <div className='message_content'>
                <h5>Get in Touch.</h5>
                <h2>Send us a Message</h2>
                <p>
                  Connect with us to discover inner peace and embark on a
                  transformative yoga journey.
                </p>
                <ContactForm />
              </div>
            </div>
            <div
              className='col-lg-4 col-md-4 col-sm-12 col-xs-12'
              data-aos='fade-right'
            >
              <div className='row' data-aos='fade-up'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                  <div className='box box_padding'>
                    <div className='box_image_content'>
                      <figure className='mb-0'>
                        <img
                          src='./assets/images/contact_location.png'
                          alt=''
                          className='img-fluid'
                        />
                      </figure>
                    </div>
                    <div className='box_wrapper'>
                      <h3>Location</h3>
                      <p className='mb-0'>
                        M05 - Waves Residence Al Mankhool, Dubai, UAE
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                  <div className='box'>
                    <div className='box_image_content'>
                      <figure className='mb-0'>
                        <img
                          src='./assets/images/contact_phone.png'
                          alt=''
                          className='img-fluid'
                        />
                      </figure>
                    </div>
                    <div className='box_wrapper'>
                      <h3>Phone</h3>
                      <p>
                        <a
                          href='tel:(+971555352235)'
                          className='text-decoration-none'
                        >
                          (+971 55 535 2235)
                        </a>
                      </p>
                      <p>
                        <a
                          href='tel:(+971509561612)'
                          className='text-decoration-none'
                        >
                          (+971 50 956 1612)
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                  <div className='box mb-0'>
                    <div className='box_image_content'>
                      <figure className='mb-0'>
                        <img
                          src='./assets/images/contact_mail.png'
                          alt=''
                          className='img-fluid'
                        />
                      </figure>
                    </div>
                    <div className='box_wrapper'>
                      <h3>Email</h3>
                      <p className='mb-0'>
                        <a
                          href='mailto:namaste@relive.yoga'
                          className='text-decoration-none'
                        >
                          namaste@relive.yoga
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CONTACT MAP SECTION */}
      <div className='contact_map_section'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d231144.19097666218!2d55.088707655815774!3d25.148265113346277!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43b3cbc2aa81%3A0x37cd77bb4a67d61b!2sReLive%20Yoga%20Center!5e0!3m2!1sen!2sin!4v1691147377345!5m2!1sen!2sin'
              width='100%'
              height={500}
              style={{ border: 0 }}
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
