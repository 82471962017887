import React from 'react';
import NavbarMenu from '../../Components/NavbarMenu';
import GenericSubBanner from '../../Components/GenericSubBanner';
import { Link } from 'react-router-dom';

const YogaPoses = () => {
  return (
    <>
      <div className='sub-banner-section'>
        <header>
          <NavbarMenu />
        </header>
        {/* SUB BANNER SECTION */}
        <GenericSubBanner
          title='Yoga for common ailments'
          subtitle='Know 20 yoga poses'
          pageName='20-yoga-poses'
        />
      </div>

      {/* DISCOUNT SECTION */}
      <section className='discount_section '>
        <div className='container'>
          <div className='row' data-aos='fade-up'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='discount_content'>
                {/* <h2>
                  20 Yoga <span>Poses</span>
                </h2> */}

                <div>
                  <div>
                    <h2>1. Breathing Trouble</h2>
                    <p>
                      Pose: Alternate Nostril Breathing
                      <br />
                      Regularly practicing it improves the lung capacity in the
                      body and clears the physical and subtle energy blockages
                      in the respiratory system.
                    </p>
                  </div>

                  <div>
                    <h2>2. Knee Pain</h2>
                    <p>
                      Pose: Ek Pada Supta Padangushthasana
                      <br />
                      Helps to strengthen the quads which are the controllers of
                      the knee cap in a non-weight bearing position.
                    </p>
                  </div>

                  <div>
                    <h2>3. Lower Back Pain</h2>
                    <p>
                      Pose: Adho Mukha Veerasana
                      <br />
                      It helps extend the lumbar spine which helps relieve it of
                      muscular stiffness and creates space in the spinal
                      vertebrae.
                    </p>
                  </div>

                  <div>
                    <h2>4. Upper Back Pain</h2>
                    <p>
                      Pose: Marjari Asana
                      <br />
                      Helps relieve stiffness and tightness around the thoracic
                      region by mobilizing the upper vertebrae.
                    </p>
                  </div>

                  <div>
                    <h2>5. Blood Pressure - High BP</h2>
                    <p>
                      Pose: Diaphragmatic Breathing
                      <br />
                      It promotes breathing from the belly button and relaxes
                      the lower abdomen which activates our parasympathetic
                      nervous system.
                    </p>
                  </div>

                  <div>
                    <h2>6. Blood Pressure - Low BP</h2>
                    <p>
                      Pose: Sarvangasana
                      <br />
                      It’s an inversion which brings the blood in the opposite
                      direction towards the heart, therefore providing more
                      oxygen and regulating the blood flow.
                    </p>
                  </div>

                  <div>
                    <h2>7. Arthritis</h2>
                    <p>
                      Pose: Sukshma Vyayama/Micro circulations
                      <br />
                      Small joint movements help bring oxygen and heat around
                      the small joints of the body where the tendons, ligaments,
                      and the muscles join which promotes rejuvenation of the
                      joint space.
                    </p>
                  </div>

                  <div>
                    <h2>8. PCOD/PCOS</h2>
                    <p>
                      Pose: Supta Baddhakona Asana
                      <br />
                      It opens the pelvis which gets rid of any impure fluids or
                      stagnation that can happen during PCOD in the pelvic area.
                    </p>
                  </div>

                  <div>
                    <h2>9. Digestion</h2>
                    <p>
                      Pose: Vajrasana
                      <br />
                      The seated position of Vajrasana is designed in such a way
                      where all the body’s subtle energy and blood flow is
                      directed towards the abdominal area, aiding in digestion.
                    </p>
                  </div>

                  <div>
                    <h2>10. Sciatica</h2>
                    <p>
                      Pose: Supta Padangusthasana
                      <br />
                      It frees the sciatic nerve by lengthening the superficial
                      and deeper muscles around the hips.
                    </p>
                  </div>

                  <div>
                    <h2>11.Depression</h2>
                    <p>
                      Pose: Bhramari
                      <br />
                      It creates strong positive vibrations within the cells of
                      the body which helps calm the nervous system and
                      specifically the cerebral cortex.
                    </p>
                  </div>

                  <div>
                    <h2>12. Diabetes</h2>
                    <p>
                      Pose: Mandukasana
                      <br />
                      It puts pressure around the pancreas which regulates the
                      insulin levels.
                    </p>
                  </div>

                  <div>
                    <h2>13. Stress and Anxiety</h2>
                    <p>
                      Pose: Supported Viprit Karni Mudra
                      <br />
                      This inversion activates the parasympathetic nervous
                      system which helps calm the mind.
                    </p>
                  </div>

                  <div>
                    <h2>14. Obesity</h2>
                    <p>
                      Pose: Naukasana
                      <br />
                      Along with strengthening the abdominal muscles, it
                      promotes better gut health and a more resilient core.
                    </p>
                  </div>

                  <div>
                    <h2>15. Scoliosis</h2>
                    <p>
                      Pose: Side Stretching in Adho Mukha Veerasana
                      <br />
                      It balances the muscular imbalance around the spine.
                    </p>
                  </div>

                  <div>
                    <h2>16. Sinus</h2>
                    <p>
                      Pose: Nadi Shodhana
                      <br />
                      Consistently practicing it keeps the sinuses regular
                      through balancing the internal body temperature.
                    </p>
                  </div>

                  <div>
                    <h2>17. Headache</h2>
                    <p>
                      Pose: Adho Mukha Swastika
                      <br />
                      It calms the racing mind and gets rid of energy blockages
                      around the cranium.
                    </p>
                  </div>

                  <div>
                    <h2>18. Insomnia</h2>
                    <p>
                      Pose: Bhramari
                      <br />
                      It quiets the mind, relieves tension, and brings harmony
                      in the various systems.
                    </p>
                  </div>

                  <div>
                    <h2>19. Menopause</h2>
                    <p>
                      Pose: Warrior 2
                      <br />
                      It opens the pelvic area and gives strength to the
                      supporting muscles that tend to get weak with age.
                    </p>
                  </div>

                  <div>
                    <h2>20. Bloating</h2>
                    <p>
                      Pose: Pawanmuktasana
                      <br />
                      Massages the abdominal organs and promotes a healthy gut
                      by relieving the stomach of trapped gas.
                    </p>
                  </div>

                  <div>
                    <h2>21. Cervical</h2>
                    <p>
                      Pose: Paschim Namaskara
                      <br />
                      Stretches the neck and shoulder muscles to create space
                      around the cervical region.
                    </p>
                  </div>
                </div>
                {/* <p className='mt-5 align-start'>
                  Oops! It seems like you've landed on a tranquil and peaceful
                  page that is currently lost in the realm of the unknown. The
                  energy flow of this digital space has taken an unexpected
                  turn, and we apologize for any inconvenience caused.
                </p>
                <p className='align-start'>
                  In our quest for inner harmony and serenity, we strive to
                  maintain balance in all aspects of life, including our
                  website. Unfortunately, the page you were searching for has
                  eluded our digital yoga mats.
                </p>
                <p className='align-start'>
                  But fret not! Just like in yoga, where we learn to adapt and
                  find stillness within, we encourage you to explore the other
                  parts of our website, where you'll discover a multitude of
                  enlightening resources, empowering classes, and transformative
                  experiences that await you.
                </p>
                <p className='align-start'>
                  Remember, yoga is a journey, and sometimes detours and
                  unexpected surprises can lead to extraordinary discoveries. So
                  take a deep breath, release any disappointment, and allow the
                  universe to guide you to an alternative path of discovery.
                </p>
                <p className='align-start'>
                  If you're seeking specific information or have any questions
                  about our yoga center, our dedicated team is always here to
                  assist you. Please don't hesitate to reach out to us through
                  our contact page or give us a call.
                </p>
                <p className='align-start'>
                  Thank you for your understanding, and may your journey towards
                  wellness, balance, and self-discovery continue with grace and
                  ease.
                </p> */}
                {/* <p className='align-start'>Namaste.</p> */}
                <div className='btn_wrapper'>
                  <Link
                    to='/contact-us'
                    className='text-decoration-none get_started'
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default YogaPoses;
