import { Link, useLocation } from 'react-router-dom';
import { useLoading } from '../context/loadingContext';

const NavbarMenu = () => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { setLoading } = useLoading();

  const isActive = (path: string) => {
    console.log(path, location.pathname);

    if (location.pathname === path) {
      return 'active';
    } else {
      return '';
    }
  };

  return (
    <header>
      <div className='main_header'>
        <div className='container-fluid'>
          <nav className='navbar navbar-expand-lg navbar-light p-0'>
            <Link className='navbar-brand' to='/'>
              <figure className='mb-0'>
                <img src='./assets/images/relive_logo_sm.png' alt='' />
              </figure>
            </Link>
            <button
              className='navbar-toggler collapsed'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon' />
              <span className='navbar-toggler-icon' />
              <span className='navbar-toggler-icon' />
            </button>
            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav'>
                <li className={`nav-item ${isActive('/')}`}>
                  <Link className='nav-link' to='/'>
                    Home
                  </Link>
                </li>
                <li className={`nav-item ${isActive('/about-us')}`}>
                  <Link className='nav-link' to='/about-us'>
                    About Us
                  </Link>
                </li>
                <li className={`nav-item ${isActive('/our-trainers')}`}>
                  <Link className='nav-link' to='/our-trainers'>
                    Our Trainers
                  </Link>
                </li>
                <li className={`nav-item ${isActive('/sessions')}`}>
                  <Link className='nav-link' to='/sessions'>
                    Sessions
                  </Link>
                </li>
                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle dropdown-color navbar-text-color'
                    href='#'
                    id='navbarDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    {' '}
                    Yoga Reads{' '}
                  </a>
                  <div className='dropdown-menu drop-down-content'>
                    <ul className='list-unstyled drop-down-pages'>
                      <li className={`nav-item ${isActive('/20-yoga-poses')}`}>
                        <Link
                          className='dropdown-item nav-link'
                          to='/20-yoga-poses'
                        >
                          Ailments & Yoga
                        </Link>
                      </li>
                      <li
                        className={`nav-item ${isActive('/10-yoga-questions')}`}
                      >
                        <Link
                          className='dropdown-item nav-link'
                          to='/10-yoga-questions'
                        >
                          FAQ's Answered
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className='nav-item'>
                  <Link className='nav-link contact_us' to='/contact-us'>
                    Contact Us
                  </Link>
                  {/* <span
                    className='nav-link contact_us'
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      setLoading(true);
                    }}
                  >
                    Contact Us
                  </span> */}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default NavbarMenu;
