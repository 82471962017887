import { useEffect } from 'react';
import './App.css';
import AppRoutes from './routes';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Components/Footer';
import Loader from './Components/Loader';
import { LoadingProvider, useLoading } from './context/loadingContext';
import { SnackbarProvider } from 'notistack';

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <SnackbarProvider>
      <LoadingProvider>
        <div className='root-content'>
          <Loader />
          <AppRoutes />
          <Footer />
          <div className='btn_wrapper fixedbutton'>
            <a
              href={'./assets/files/brochure.pdf'}
              // download='relive-brochure'
              target='_blank'
              className='text-decoration-none download-btn'
              rel='noreferrer'
            >
              <i className='fa fa-eye' aria-hidden='true' /> &nbsp; Brochure
            </a>
          </div>
        </div>
      </LoadingProvider>
    </SnackbarProvider>
  );
}

export default App;
