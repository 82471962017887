import React from 'react';
import { Link } from 'react-router-dom';

type GenericSubBannerProps = {
  title: string;
  subtitle: string;
  pageName: string;
};

const GenericSubBanner = (props: GenericSubBannerProps) => {
  const { title, subtitle, pageName } = props;
  return (
    <section className='banner-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <div className='banner-section-content'>
              <h1 data-aos='fade-up'>{title}</h1>
              <p data-aos='fade-right'>{subtitle}</p>
              <div className='btn_wrapper'>
                <Link className='sub_home_span' to='/'>
                  Home
                </Link>
                <i className='fa-solid fa-angles-right' aria-hidden='true' />
                <span className='sub_span'> {pageName}</span>
              </div>
            </div>
          </div>
        </div>
        <figure className='banner_left_top_shape left_shape mb-0'>
          <img
            src='./assets/images/banner_left_top_shape.png'
            alt=''
            className='img-fluid'
          />
        </figure>
        <figure className='banner_left_bottom_shape left_shape mb-0'>
          <img
            src='./assets/images/banner_left_bottom_shape.png'
            alt=''
            className='img-fluid'
          />
        </figure>
        <figure className='banner_right_top_shape right_shape mb-0'>
          <img
            src='./assets/images/banner_right_top_shape.png'
            alt=''
            className='img-fluid'
          />
        </figure>
        <figure className='banner_right_bottom_shape right_shape mb-0'>
          <img
            src='./assets/images/banner_right_bottom_shape.png'
            alt=''
            className='img-fluid'
          />
        </figure>
      </div>
    </section>
  );
};

export default GenericSubBanner;
