/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useEffect, useState } from 'react';

type LoadingContextType = {
  loading: boolean;
  setLoading: any;
};
const LoadingContext = createContext<LoadingContextType>({
  loading: false,
  setLoading: null,
});

export function LoadingProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(loading);
  }, [loading]);
  const value = { loading, setLoading };
  // eslint-disable-next-line
  return (
    // eslint-disable-next-line
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
