import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import GenericSubBanner from '../../Components/GenericSubBanner';
import NavbarMenu from '../../Components/NavbarMenu';
import { ReadMore, ReadMoreMulti } from '../../Components/Readmore';

const About = () => {
  const galleryRef = useRef<null | HTMLDivElement>(null);
  const timingRef = useRef<null | HTMLDivElement>(null);
  const trainerRef = useRef<null | HTMLDivElement>(null);
  const ambianceRef = useRef<null | HTMLDivElement>(null);
  const prenatalRef = useRef<null | HTMLDivElement>(null);
  const kidsRef = useRef<null | HTMLDivElement>(null);

  const scrollToNext = (
    refName: React.MutableRefObject<HTMLDivElement | null>
  ) => {
    if (refName && refName.current) {
      refName.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <div className='sub-banner-section'>
        <header>
          <NavbarMenu />
        </header>
        {/* SUB BANNER SECTION */}
        <GenericSubBanner
          title='About Us'
          subtitle='Find balance and serenity at our yoga center, where mind, body, and spirit unite in harmony.'
          pageName='About Us'
        />
      </div>
      {/* ABOUT US SECTION */}
      <section className='aboutpage_aboutus_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-6 col-sm-12 col-xs-12'>
              <div className='aboutpage_aboutus_image'>
                <figure className='mb-0'>
                  <img
                    // src='./assets/images/about-us.jpg'
                    src='./assets/images/new-age.jpeg'
                    alt=''
                    className='img-fluid'
                  />
                </figure>
              </div>
              <figure className='aboutus_top_shape left_shape mb-0'>
                <img
                  src='./assets/images/aboutus_top_shape.png'
                  alt=''
                  className='img-fluid'
                />
              </figure>
              <figure className='aboutus_bottom_shape left_shape mb-0'>
                <img
                  src='./assets/images/aboutus_bottom_shape.png'
                  alt=''
                  className='img-fluid'
                />
              </figure>
            </div>
            <div
              className='col-lg-5 col-md-6 col-sm-12 col-xs-12'
              data-aos='fade-right'
            >
              <div className='aboutpage_aboutus_content'>
                <h5>About us</h5>
                <h2>A New Age Yoga Studio Exclusively for Women</h2>

                <p className='margin_bottom'>
                  Women is symbolic to Strength, Peace, and Power and we believe
                  the time-tested practice of Yoga becomes a natural fit as it
                  compliments today’s women in their day-to- day life. We at
                  ReLive offers Yoga in its Original and Traditional form -
                  Because you deserve only the best.
                </p>
                <div className='box_wrapper'>
                  <div
                    className='box'
                    onClick={() => {
                      scrollToNext(galleryRef);
                    }}
                  >
                    <i className='fa-solid fa-check' aria-hidden='true' />
                    <span>Traditional Yoga</span>
                  </div>
                  <div
                    className='box mr-0'
                    onClick={() => {
                      scrollToNext(timingRef);
                    }}
                  >
                    <i className='fa-solid fa-check' aria-hidden='true' />
                    <span>Flexible Timings</span>
                  </div>
                </div>
                <div className='box_wrapper'>
                  <div
                    className='box'
                    onClick={() => {
                      scrollToNext(trainerRef);
                    }}
                  >
                    <i className='fa-solid fa-check' aria-hidden='true' />
                    <span>Experienced Trainers</span>
                  </div>
                  <div
                    className='box mr-0'
                    onClick={() => {
                      scrollToNext(ambianceRef);
                    }}
                  >
                    <i className='fa-solid fa-check' aria-hidden='true' />
                    <span>Vibrant Ambience</span>
                  </div>
                </div>
                <div className='box_wrapper'>
                  <div
                    className='box mr-0'
                    onClick={() => {
                      scrollToNext(prenatalRef);
                    }}
                  >
                    <i className='fa-solid fa-check' aria-hidden='true' />
                    <span>Prenatal &amp; Postnatal Yoga</span>
                  </div>
                  <div
                    className='box mr-0'
                    onClick={() => {
                      scrollToNext(kidsRef);
                    }}
                  >
                    <i className='fa-solid fa-check' aria-hidden='true' />
                    <span>Kids {'\n'} Yoga</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* OUR MISSION SECTION */}
      <section className='mission_section gray-section' ref={galleryRef}>
        <div className='container'>
          <div className='mission_box'>
            <div className='row'>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='mission_content'>
                  {/* <h5>incididunt</h5> */}
                  <h2>Traditional Yoga</h2>
                  <p>
                    {/* Ashtanga */}
                    Our offerings are Hatha &amp; Vinyasa forms of Yoga and this
                    practice is age old and proven to be one of the most
                    valuable forms to build your core strengths.
                  </p>
                  <div className='btn_wrapper'>
                    <Link
                      to='/gallery'
                      className='text-decoration-none read_more_btn'
                    >
                      Gallery
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='mission_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/traditional-yoga.jpeg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <figure className='mission_right_shape right_shape mb-0'>
            <img
              src='./assets/images/our_mission_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      {/* OUR VISION SECTION */}
      <section className='vision_section' ref={timingRef}>
        <div className='container'>
          <div className='vision_box'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='vision_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/flexible-timing.jpeg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='vision_content'>
                  <h2>Flexible Timings</h2>
                  <p>
                    We understand you are more than just one person and you wear
                    many hats and hence we have made our schedule to suit your
                    needs. Be it the busy morning or the busier evenings, we are
                    there for you
                  </p>
                  <div className='btn_wrapper'>
                    <Link
                      to='/sessions'
                      className='text-decoration-none read_more_btn'
                    >
                      View Sessions
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figure className='vision_left_shape left_shape mb-0'>
            <img
              src='./assets/images/our_vision_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      {/* OUR MISSION SECTION */}
      <section className='mission_section gray-section' ref={trainerRef}>
        <div className='container'>
          <div className='mission_box'>
            <div className='row'>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='mission_content'>
                  <h2>Experienced Trainers</h2>
                  <p>
                    Our well-versed trainers come with practice and training
                    experience in cities across the globe, be it the
                    cosmopolitan Macau to meditating town of Rishikesh or ever
                    calm Muscat or our very own Dubai
                  </p>
                  <div className='btn_wrapper'>
                    <Link
                      to='/our-trainers'
                      className='text-decoration-none read_more_btn'
                    >
                      View Trainers
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='mission_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/experienced-trainer.jpeg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <figure className='mission_right_shape right_shape mb-0'>
            <img
              src='./assets/images/our_mission_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      {/* OUR VISION SECTION */}
      <section className='vision_section' ref={ambianceRef}>
        <div className='container'>
          <div className='vision_box'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='vision_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/vibrant-ambience.jpeg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='vision_content'>
                  <h2>Vibrant Ambience</h2>
                  <p>
                    Yoga is best practiced in an atmosphere that is calm &amp;
                    soothing. The vibrant décor of our studio and its peaceful
                    ambience make it a winning combination for you to start your
                    journey with us. We are centered in your neighborhood in Al
                    Mankhool
                  </p>
                  <div className='btn_wrapper'>
                    <a
                      target='_blank'
                      href='https://g.co/kgs/2szjnz'
                      className='text-decoration-none read_more_btn'
                      rel='noreferrer'
                    >
                      View on Map
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figure className='vision_left_shape left_shape mb-0'>
            <img
              src='./assets/images/our_vision_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>

      {/* OUR MISSION SECTION */}
      <section className='mission_section gray-section' ref={prenatalRef}>
        <div className='container'>
          <div className='mission_box'>
            <div className='row'>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='mission_content'>
                  <h2>Prenatal &amp; Postnatal Yoga</h2>
                  <p>
                    <ReadMore
                      slice={250}
                      text='Pregnancy is beautiful as much as it is stressful for some
                    hence engaging in Prenatal Yoga helps multiple semesters of
                    your pregnancy. On the other hand once you have delivered
                    your little one, being a Mom &amp; yet to remain in great
                    shape is also not an easy task. Our Prenatal &amp; postnatal
                    classes cover the various aspects of Yoga that is well
                    suited to cover the balance between emotional, physical and
                    mental dimensions.'
                    />
                  </p>
                  <div className='btn_wrapper'>
                    <Link
                      to='/sessions'
                      className='text-decoration-none read_more_btn'
                    >
                      View Sessions
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='mission_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/prenatal-yoga.jpg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <figure className='mission_right_shape right_shape mb-0'>
            <img
              src='./assets/images/our_mission_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      {/* OUR VISION SECTION */}
      <section className='vision_section' ref={kidsRef}>
        <div className='container'>
          <div className='vision_box'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='vision_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/kids-yoga.jpg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='vision_content'>
                  <h2>Kids Yoga</h2>
                  <p>
                    <ReadMore
                      slice={250}
                      text='Its not easy always to engage your children in healthy
                      exercises at home hence a group Yoga session in our studio
                      is big hit with parents who loves to see their kids do
                      yoga that helps them. While kids as young as 3 years can
                      be taught basic yoga forms, we believe age 5-6 and beyond
                      are the best age when one can introduce their kid to Yoga.
                      Children up to age 12 can benefit from various Yoga forms
                      that our qualified and experienced trainers teach at
                      ReLive.'
                    ></ReadMore>
                  </p>
                  <div className='btn_wrapper'>
                    <Link
                      to='/sessions'
                      className='text-decoration-none read_more_btn'
                    >
                      View Sessions
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figure className='vision_left_shape left_shape mb-0'>
            <img
              src='./assets/images/our_vision_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
    </>
  );
};

export default About;
