import React from 'react';
import { useLoading } from '../context/loadingContext';

const Loader = () => {
  const { loading } = useLoading();
  if (!loading) return <></>;
  return <div className='loading' />;
};

export default Loader;
