import { useEffect, useRef } from 'react';
import { init } from 'ityped';
// import BrochurePDF from './assets/files/brochure.pdf';

const TopBannerSection = () => {
  const itypedTextRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!itypedTextRef.current) {
      return;
    }
    init(itypedTextRef.current, {
      showCursor: true,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 1000,
      disableBackTyping: true,
      loop: false,
      strings: ['Strength, Peace & Power'],
    });
  }, []);

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const scrollToNext = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <div className='left_icons float-left d-table' data-aos='fade-down'>
        <div className='icon_content d-table-cell align-middle'>
          <ul className='list-unstyled p-0 m-0'>
            <li>
              <a
                href='https://www.facebook.com/yogarelive'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-facebook-f' aria-hidden='true' />
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/ReliveYog'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-twitter' aria-hidden='true' />
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/reliveyog/'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-instagram' aria-hidden='true' />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* BANNER SECTION */}
      <section className='banner-section'>
        <div className='container-fluid'>
          <div className='row' data-aos='fade-up'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 text-md-left text-center'>
              <div className='banner-section-content'>
                <h5>
                  <b>A New Age Yoga Studio Exclusively for Women</b>
                </h5>
                <h1 data-aos='fade-up' style={{ minHeight: '100px' }}>
                  You are symbolic to
                  <br /> <span ref={itypedTextRef} />
                </h1>
                <p data-aos='fade-right'>
                  Hence we believe the Traditional Yoga practice is a natural
                  fit
                </p>
                {/* <div className='btn_wrapper' data-aos='fade-up'>
                  <a
                    href={'./assets/files/brochure.pdf'}
                    download='relive-brochure'
                    target='_blank'
                    className='text-decoration-none getstarted_btn'
                  >
                    Download Brochure
                  </a>
                </div> */}
                <span className='top-btn' onClick={scrollToNext}>
                  <i className='fa-solid fa-arrow-down-long' />
                </span>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
              <div className='banner-section-image'>
                <figure className='mb-0'>
                  <img src='./assets/images/yoga-girl-mandala.png' alt='' />
                </figure>
              </div>
            </div>
          </div>
          <figure className='banner_left_top_shape left_shape mb-0'>
            <img
              src='./assets/images/banner_left_top_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
          <figure className='banner_left_bottom_shape left_shape mb-0'>
            <img
              src='./assets/images/banner_left_bottom_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
          <figure className='banner_right_top_shape right_shape mb-0'>
            <img
              src='./assets/images/banner_right_top_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
          <figure className='banner_right_bottom_shape right_shape mb-0'>
            <img
              src='./assets/images/banner_right_bottom_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      <div ref={scrollRef} />
    </>
  );
};

export default TopBannerSection;
