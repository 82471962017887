import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import NavbarMenu from '../../Components/NavbarMenu';
import TopBannerSection from './TopBannerSection';
import ContactForm from '../Contact/ContactForm';
import { ReadMore } from '../../Components/Readmore';

const Home = () => {
  return (
    <>
      <div className='banner-section-outer'>
        <NavbarMenu />
        <TopBannerSection />
      </div>
      {/* OUR SERVICES SECTION */}
      <section className='services_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='services_content'>
                <h5>What We Offer</h5>
                <h2>
                  The answers you seek is within. Reasons why you would love us
                </h2>
                {/* <p>
                  Taciti fames lacinia orci finibus metus elit tempus faucibus
                  urna nunc dui rhoncus aibendum vea porttitor volutrat felis
                  massa feugiat
                </p> */}
              </div>
            </div>
          </div>
          <div className='row mt-3' data-aos='fade-up'>
            <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12'>
              <div className='services_box_content'>
                <div className='services_box_upper_portion'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/yoga-forms.jpeg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
                <div className='services_box_lower_portion'>
                  <h3>Yoga Forms</h3>
                  <p>
                    <ReadMore
                      text={`Our offerings are Hatha, Ashtanga & Vinyasa forms of Yoga
                    and this practice is age old and proven to be one of the
                    most valuable forms to build your core strengths`}
                    />
                  </p>
                  <div className='btn_wrapper'>
                    <Link to='about-us' className='text-decoration-none'>
                      <i
                        className='fa-solid fa-arrow-right'
                        aria-hidden='true'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12'>
              <div className='services_box_content'>
                <div className='services_box_upper_portion'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/timing.jpeg'
                      alt=''
                      className='img-fluid object-fit-fill'
                    />
                  </figure>
                </div>
                <div className='services_box_lower_portion'>
                  <h3>Timings</h3>
                  <p>
                    <ReadMore
                      text={`We understand you are more than just one person and you wear
                    many hats and hence we have made our schedule to suit your
                    needs. Be it the busy morning or the busier evenings, we are
                    there for you`}
                    />
                  </p>
                  <div className='btn_wrapper'>
                    <Link to='/sessions' className='text-decoration-none'>
                      <i
                        className='fa-solid fa-arrow-right'
                        aria-hidden='true'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12'>
              <div className='services_box_content'>
                <div className='services_box_upper_portion'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/trainers.jpeg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
                <div className='services_box_lower_portion'>
                  <h3>Trainers</h3>
                  <p>
                    <ReadMore
                      text={`Our well-versed trainers come with practice and training
                    experience in cities across the globe, be it the
                    cosmopolitan Macau to meditating town of Rishikesh or ever
                    calm Muscat or our very own Dubai`}
                    />
                  </p>
                  <div className='btn_wrapper'>
                    <Link to='/our-trainers' className='text-decoration-none'>
                      <i
                        className='fa-solid fa-arrow-right'
                        aria-hidden='true'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12'>
              <div className='services_box_content'>
                <div className='services_box_upper_portion'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/ambience.jpeg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
                <div className='services_box_lower_portion'>
                  <h3>Ambience</h3>
                  <p>
                    <ReadMore
                      text={`Yoga is best practiced in an atmosphere that is calm &
                    soothing. The vibrant décor of our studio and its peaceful
                    ambience make it a winning combination for you to start your
                    journey with us. We are centered in your neighborhood in Al
                    Mankhool`}
                    />
                  </p>
                  <div className='btn_wrapper'>
                    <Link to='/gallery' className='text-decoration-none'>
                      <i
                        className='fa-solid fa-arrow-right'
                        aria-hidden='true'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figure className='services_left_shape left_shape mb-0'>
            <img
              src='./assets/images/services_left_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      {/* ABOUT US SECTION */}
      <section className='aboutus_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-7 col-sm-12 col-xs-12'>
              <div className='aboutus_image'>
                <figure className='mb-0'>
                  <img
                    // src='./assets/images/about-us.jpg'
                    src='./assets/images/deserve-best.jpeg'
                    alt=''
                    className='img-fluid'
                  />
                </figure>
              </div>

              <figure className='aboutus_top_shape left_shape mb-0'>
                <img
                  src='./assets/images/aboutus_top_shape.png'
                  alt=''
                  className='img-fluid'
                />
              </figure>
              <figure className='aboutus_bottom_shape left_shape mb-0'>
                <img
                  src='./assets/images/aboutus_bottom_shape.png'
                  alt=''
                  className='img-fluid'
                />
              </figure>
            </div>
            <div
              className='col-lg-5 col-md-5 col-sm-12 col-xs-12'
              data-aos='fade-right'
            >
              <div className='aboutus_content'>
                <h5>About us</h5>
                <h2>Because You Deserve the Best</h2>
                <p>
                  Yoga, an ancient practice, is today offered in many forms and
                  fashions. We at ReLive believe firmly that Original can never
                  lose its charm even though one tries its best to reinvent the
                  wheel and hence our offering is true to its original form.
                  Because you deserve only the best.
                </p>
                <div className='aboutus_line_wrapper'>
                  <h6>
                    <ReadMore
                      slice={100}
                      text={`Yoga is best practiced in an atmosphere that is calm &
                    soothing. The vibrant décor of our studio and its ambience
                    coupled with our dedicated and pleasing trainers make it a
                    winning combination for you to start your journey with us`}
                    />
                  </h6>
                </div>
                <div className='btn_wrapper'>
                  <Link
                    to='/about-us'
                    className='text-decoration-none get_started_btn'
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* OUR SPECIALTIES SECTION */}
      <section className='our_specialties_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='our_specialties_heading_content'>
                <h5>At a Glance</h5>
                <h2>Our offerings, Keeping YOU in mind</h2>
                <p>
                  ReLive which means to Live again was born out of passion to
                  offer todays women a meaningful and effective way to get back
                  to their best form
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div
              className='col-lg-3 col-md-4 col-sm-6 col-xs-12'
              data-aos='fade-right'
            >
              <div className='specialties_content_wrapper'>
                <div className='specialties_content s1'>
                  <p className='specialties_name'>Hatha Yoga</p>
                  <p className='specialties_paragraph'>
                    Conserve Energy, Create Awareness in the Body
                  </p>
                  <br className='d-none d-lg-block' />
                </div>
                <div className='specialties_content s2'>
                  <p className='specialties_name'>Vinyasa Yoga</p>
                  <p className='specialties_paragraph'>
                    Breath cooridnated postures
                  </p>
                  <br className='d-none d-lg-block' />
                </div>
                <div className='specialties_content s3'>
                  <p className='specialties_name'>Prenatal Yoga</p>
                  <p className='specialties_paragraph'>
                    Make your pregnancy even more beautiful
                  </p>
                </div>
                <div className='specialties_content s4'>
                  <p className='specialties_name'>Postnatal Yoga</p>
                  <p className='specialties_paragraph'>
                    Part of taking care of your baby is taking care of yourself
                  </p>
                </div>
                <figure className='specialties_left_line mb-0'>
                  <img
                    src='./assets/images/specialties_left_line.png'
                    alt=''
                    className='img-fluid'
                  />
                </figure>
              </div>
            </div>
            <div className='col-lg-6 col-md-4 col-sm-6 col-xs-12 d-md-block d-none'>
              <figure className='specialties_image mb-0'>
                <img
                  src='./assets/images/specialties_image.png'
                  alt=''
                  className='img-fluid'
                />
              </figure>
            </div>
            <div
              className='col-lg-3 col-md-4 col-sm-6 col-xs-12'
              data-aos='fade-right'
            >
              <div className='specialties_content_wrapper'>
                <div className='specialties_content specialties_content2 s5'>
                  <p className='specialties_name'>Flexible Timing</p>
                  <p className='specialties_paragraph'>
                    Be it the busy morning or the busier evenings, we are there
                    for you
                  </p>
                </div>
                <div className='specialties_content specialties_content2 s6'>
                  <p className='specialties_name'>Kids Zone</p>
                  <p className='specialties_paragraph'>
                    We have a small space dedicated to the little hearts
                  </p>
                </div>
                <div className='specialties_content specialties_content2 s7'>
                  <p className='specialties_name'>Experienced Trainers</p>
                  <p className='specialties_paragraph'>
                    Calm, Composed & pleasing trainers to make you feel at ease
                  </p>
                </div>
                <div className='specialties_content specialties_content2 s8'>
                  <p className='specialties_name'>Elderly Parents</p>
                  <p className='specialties_paragraph'>
                    We have your parents covered too
                  </p>
                </div>
                <figure className='specialties_right_line mb-0'>
                  <img
                    src='./assets/images/specialties_right_line.png'
                    alt=''
                    className='img-fluid'
                  />
                </figure>
              </div>
            </div>
          </div>
          <figure className='our_specialties_right_shape right_shape mb-0'>
            <img
              src='./assets/images/our_specialties_right_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      {/* GET IN TOUCH SECTION */}
      <section className='get_in_touch_section'>
        <div className='container'>
          <div className='row' data-aos='fade-up'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
              <div className='get_in_touch_content'>
                <h5>Reach us</h5>
                <h2>Tell us a little about you</h2>
                <ContactForm />
                {/* <form>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                      <div className='form-group mb-0'>
                        <input
                          type='text'
                          name='fname'
                          id='fname'
                          className='form-control'
                          placeholder='First Name'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                      <div className='form-group mb-0'>
                        <input
                          type='text'
                          name='lname'
                          id='lname'
                          className='form-control form_style'
                          placeholder='Last Name'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                      <div className='form-group mb-0'>
                        <input
                          type='tel'
                          name='phonenum'
                          id='phonenum'
                          className='form-control'
                          placeholder='Phone'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                      <div className='form-group mb-0'>
                        <input
                          type='email'
                          name='emailaddrs'
                          id='emailaddrs'
                          className='form-control form_style'
                          placeholder='Email'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className=' form-group mb-0'>
                        <textarea
                          rows={3}
                          name='comment'
                          id='msg'
                          className='form-control'
                          placeholder='Message'
                          defaultValue={''}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='btn_wrapper'>
                    <button type='submit' name='get_started' id='started'>
                      Get Started
                    </button>
                  </div>
                </form> */}
              </div>
            </div>
            {/* <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.723884486123!2d144.95488781489115!3d-37.819935979751264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d52754eaecb%3A0x22f367daf52cbd47!2s21%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2s!4v1666677747590!5m2!1sen!2s'
                width='100%'
                height='100%'
                style={{ border: 0 }}
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
              />
            </div> */}
            <div className='col-lg-3 col-md-3 col-sm-2 col-xs-12 d-md-block d-none' />
            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
              <div className='get_in_touch_video position-relative'>
                <a
                  className='popup-youtube'
                  href='https://www.youtube.com/watch?v=osQ1b5x-KXc'
                >
                  <figure className='video_img mb-0'>
                    <img
                      className='thumb img-fluid'
                      style={{ cursor: 'pointer' }}
                      src='./assets/images/get_in_touch_video_icon.png'
                      alt=''
                    />
                  </figure>
                </a>
              </div>
            </div>
          </div>
          <figure className='get_in_touch_shape left_shape mb-0'>
            <img
              src='./assets/images/get_in_touch_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
    </>
  );
};

export default Home;
