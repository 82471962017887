/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { log } from 'console';
import React, { useState } from 'react';
import { ContactData, submitContact } from '../../api/contact';
import { GraphQLBodyType } from '../../api/types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import { useLoading } from '../../context/loadingContext';

type formErrors = {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  message?: string;
};

const ContactForm = () => {
  const initialForm = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: '',
  };
  const { setLoading } = useLoading();

  const [form, setForm] = useState<ContactData>(initialForm);
  const [errors, setErrors] = useState<formErrors>({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const validateForm = () => {
    const formErrors: formErrors = {};

    if (!form.first_name.trim()) {
      formErrors.first_name = 'First name is required';
    }

    if (!form.last_name.trim()) {
      formErrors.last_name = 'Last name is required';
    }

    if (!form.email.trim()) {
      formErrors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(form.email)) {
      formErrors.email = 'Email is invalid';
    }

    if (!form.phone.trim()) {
      formErrors.phone = 'Phone is required';
    } else if (!/^\d{10}$/.test(form.phone)) {
      formErrors.phone = 'Phone is invalid';
    }

    if (!form.message.trim()) {
      formErrors.message = 'Message is required';
    }

    setErrors(formErrors);

    // Return true if there are no errors
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const bodyQuery: GraphQLBodyType = {
          query: `mutation CreateContact($createContactInput: CreateContactInput!) {
            createContact(createContactInput: $createContactInput) {
              id
              first_name
              last_name
              email
              phone
              message
            }
          }`,
          variables: {
            createContactInput: form,
          },
        };
        const contactResponse: unknown = await submitContact(bodyQuery);

        if (contactResponse) {
          setForm(initialForm);
          enqueueSnackbar('Contact request submitted successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          });
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      enqueueSnackbar('Could not validate form. Please fill in the form', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <>
      <form
        id='contactpage'
        onSubmit={(e) => {
          void handleSubmit(e);
        }}
      >
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='form-group mb-0'>
              <input
                type='text'
                name='first_name'
                value={form.first_name}
                onChange={handleChange}
                className='form-control'
                placeholder='First Name'
              />
              {errors.first_name && <span>{errors.first_name}</span>}
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='form-group mb-0'>
              <input
                type='text'
                name='last_name'
                value={form.last_name}
                onChange={handleChange}
                className='form-control form_style'
                placeholder='Last Name'
              />
              {errors.last_name && (
                <span className='form_style'>{errors.last_name}</span>
              )}
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='form-group mb-0'>
              <input
                type='tel'
                name='phone'
                value={form.phone}
                onChange={handleChange}
                className='form-control'
                placeholder='Phone'
              />{' '}
              {errors.phone && <span>{errors.phone}</span>}
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-6'>
            <div className='form-group mb-0'>
              <input
                type='email'
                name='email'
                value={form.email}
                onChange={handleChange}
                className='form-control form_style'
                placeholder='Email'
              />{' '}
              {errors.email && (
                <span className='form_style'>{errors.email}</span>
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='form-group mb-0'>
              <textarea
                rows={3}
                name='message'
                value={form.message}
                onChange={handleChange}
                className='form-control'
                placeholder='Message'
                defaultValue={''}
              />{' '}
              {errors.message && <span>{errors.message}</span>}
            </div>
          </div>
        </div>
        <div className='btn_wrapper'>
          <button type='submit' name='get_started' id='started'>
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
