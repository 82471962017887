/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState } from 'react';

type ReadMoreType = {
  slice?: number;
  text: string;
};
const ReadMore = ({ slice, text }: ReadMoreType) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {isReadMore ? text.slice(0, slice ?? 150) : text}
      <span onClick={toggleReadMore} className='read-or-hide'>
        {isReadMore ? ' ...read more' : ' show less'}
      </span>
    </>
  );
};

type ReadMoreMultiType = {
  slice: number;
  children: JSX.Element[];
};
const ReadMoreMulti = ({ slice, children }: ReadMoreMultiType): JSX.Element => {
  function short(arr: JSX.Element[], maxLen: number): JSX.Element[] {
    let l = 0;
    for (let i = 0; i < arr.length; i++) {
      if (l + arr[i].props.children.length > maxLen) {
        let res = arr.slice(0, i);
        const partial = arr[i].props.children.substring(0, maxLen - l);
        res.push(
          <p>
            {partial}{' '}
            <span
              onClick={() => setShortMode(!shortMode)}
              className='read-or-hide'
            >
              {shortMode ? ' ...read more' : ' show less'}
            </span>
          </p>
        );
        return res;
      }
      l = l + arr[i].props.children.length;
    }
    return arr;
  }

  const [shortMode, setShortMode] = useState(true);
  return (
    <>
      {shortMode ? <>{short(children, slice ?? 250)}</> : children}
      <p onClick={() => setShortMode(!shortMode)} className='read-or-hide mb-4'>
        {!shortMode && ' show less'}
      </p>
    </>
  );
};

export { ReadMore, ReadMoreMulti };
