import React from 'react';
import NavbarMenu from '../../Components/NavbarMenu';
import GenericSubBanner from '../../Components/GenericSubBanner';
import { Link } from 'react-router-dom';

const YogaQuestions = () => {
  return (
    <>
      <div className='sub-banner-section'>
        <header>
          <NavbarMenu />
        </header>
        {/* SUB BANNER SECTION */}
        <GenericSubBanner
          title='All your questions answered'
          subtitle='Have questions?'
          pageName='10-yoga-questions'
        />
      </div>

      {/* DISCOUNT SECTION */}
      <section className='discount_section '>
        <div className='container'>
          <div className='row' data-aos='fade-up'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='discount_content'>
                {/* <h2>
                  10 Yoga <span>Questions</span>
                </h2> */}
                <div>
                  <h2>1. Why should I do Yoga?</h2>
                  <p>
                    A regular Yoga practice has many benefits be it physical,
                    emotional, mental and spiritual. It makes us stronger, clear
                    headed and focused. It also helps to release stress and
                    anxiety. It works miraculously on medical ailments and
                    postural deformity. It opens the channels of energy to flow
                    free in the body which also helps in better blood
                    circulation. It promotes better growth of self.
                  </p>

                  <h2>2. What is the best time to do Yoga?</h2>
                  <p>
                    Depending on the schedule, most people find it ideal to
                    practice yoga as the beginning of the day and some find it
                    better more towards the evening to unwind a hectic day.
                    Morning and evening sessions have their own advantages.
                    Morning sessions help to stay active all day long and carry
                    out day-to-day activities. Evening sessions help to release
                    all the stress of the day and help you with better sleep.
                  </p>

                  <h2>3. At what age can we start practicing Yoga?</h2>
                  <p>
                    There is no age to begin Yoga. However, at the age of 5+, we
                    can begin practicing yoga. Starting at an early age promotes
                    a better body and focused mind.
                  </p>

                  <h2>4. Can kids do Yoga?</h2>
                  <p>
                    Mental and physical growth in kids begins at 6-12 years of
                    age. Practicing Yoga can bring many psychological benefits
                    for children. It helps to improve strength and flexibility.
                  </p>

                  <h2>5. What are the health benefits of Yoga?</h2>
                  <ul className='list-group'>
                    <li className='list-group-item'>Physical fitness</li>
                    <li className='list-group-item'>Mental stability</li>
                    <li className='list-group-item'>Controlled emotions</li>
                    <li className='list-group-item'>More focus</li>
                    <li className='list-group-item'>Relief from any pain</li>
                    <li className='list-group-item'>
                      Reduces stress and tension
                    </li>
                    <li className='list-group-item'>Maintains strength</li>
                    <li className='list-group-item'>Improves stamina</li>
                    <li className='list-group-item'>Creates awareness</li>
                    <li className='list-group-item'>
                      Boosts metabolism and digestion
                    </li>
                  </ul>

                  <h2>6. How long should I practice Yoga daily?</h2>
                  <p>
                    At least 30 minutes of practice to as long as an individual
                    can take. 2-5 times of practice every week is good depending
                    on oneself.
                  </p>

                  <h2>7. Can I gain weight by doing Yoga?</h2>
                  <p>
                    Yoga will not cause weight gain, but it will help to build
                    muscular strength. Yoga helps in building a tighter
                    physique.
                  </p>

                  <h2>8. Which pregnancy semester can I do Yoga?</h2>
                  <p>
                    In the first 3 months of pregnancy, the body goes through
                    major changes. Hence, it is very beneficial to practice yoga
                    in the first trimester. The 2nd trimester can help create
                    mobility in the pelvis. The body becomes more aware. For the
                    3rd trimester, medical advice is necessary.
                  </p>

                  <h2>9. Which form of Yoga is most beneficial?</h2>
                  <p>
                    Hatha Yoga is most beneficial for beginners. Ashtanga is
                    more athletic and intense. Raja Yoga is the ultimate form of
                    yoga for those who seek enlightenment.
                  </p>

                  <h2>10. Can Ido Yoga on my own by watching YouTube?</h2>
                  <p>
                    Yes, one can always learn the basic Yoga poses by watching
                    YouTube. However, being with a certified trainer who will
                    guide you on the journey is very important. They can correct
                    your poses, help you go that extra mile, and guide you to
                    the next steps. Yoga is a practice that requires continuous
                    monitoring and improvement to suit your health and
                    flexibility, and hence being with a Yoga trainer is
                    considered the appropriate option.
                  </p>
                </div>
                {/* <p className='mt-5 align-start'>
                  Oops! It seems like you've landed on a tranquil and peaceful
                  page that is currently lost in the realm of the unknown. The
                  energy flow of this digital space has taken an unexpected
                  turn, and we apologize for any inconvenience caused.
                </p>
                <p className='align-start'>
                  In our quest for inner harmony and serenity, we strive to
                  maintain balance in all aspects of life, including our
                  website. Unfortunately, the page you were searching for has
                  eluded our digital yoga mats.
                </p>
                <p className='align-start'>
                  But fret not! Just like in yoga, where we learn to adapt and
                  find stillness within, we encourage you to explore the other
                  parts of our website, where you'll discover a multitude of
                  enlightening resources, empowering classes, and transformative
                  experiences that await you.
                </p>
                <p className='align-start'>
                  Remember, yoga is a journey, and sometimes detours and
                  unexpected surprises can lead to extraordinary discoveries. So
                  take a deep breath, release any disappointment, and allow the
                  universe to guide you to an alternative path of discovery.
                </p>
                <p className='align-start'>
                  If you're seeking specific information or have any questions
                  about our yoga center, our dedicated team is always here to
                  assist you. Please don't hesitate to reach out to us through
                  our contact page or give us a call.
                </p>
                <p className='align-start'>
                  Thank you for your understanding, and may your journey towards
                  wellness, balance, and self-discovery continue with grace and
                  ease.
                </p> */}
                {/* <p className='align-start'>Namaste.</p> */}
                <div className='btn_wrapper'>
                  <Link
                    to='/contact-us'
                    className='text-decoration-none get_started'
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default YogaQuestions;
