import { _request } from './requests';
import { GraphQLBodyType } from './types';

export type ContactData = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  message: string;
};

export const submitContact = async (body: GraphQLBodyType) => {
  const responseBody = await _request<unknown>({
    url: ``,
    method: 'POST',
    body: body,
  });
  return responseBody;
};
