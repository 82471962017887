import React from 'react';
import NavbarMenu from '../../Components/NavbarMenu';
import GenericSubBanner from '../../Components/GenericSubBanner';
import { Link } from 'react-router-dom';
import { ReadMore, ReadMoreMulti } from '../../Components/Readmore';

const Trainers = () => {
  return (
    <>
      <div className='sub-banner-section'>
        <header>
          <NavbarMenu />
        </header>
        {/* SUB BANNER SECTION */}
        <GenericSubBanner
          title='Our Trainers'
          subtitle='Expert yoga trainers empowering your wellness journey towards inner peace.'
          pageName='our-trainers'
        />
      </div>

      {/* OUR MISSION SECTION */}
      <section className='mission_section '>
        <div className='container'>
          <div className='mission_box'>
            <div className='row'>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='mission_content'>
                  <h2>Priya Gambhir</h2>
                  <h5>YTTC 200 </h5>
                  <ReadMoreMulti slice={250}>
                    <p>
                      She started her career as a theatre artist in 2016 with
                      one of Indias biggest theatre companies, The Kingdom of
                      Dreams. It was a chance encounter with her Yoga Guru that
                      made her way into the world of Yoga. She is a certified
                      Yoga Trainer who comes in with multi geographical exposure
                      and experience across Hatha &amp; Vinyasa forms of Yoga
                      from Macau in the Far East to Muscat in the Middle East.
                    </p>
                    <p>
                      Priya hails from Delhi, India and loves dancing and
                      teaching. She uses the learning from her Bachelor of
                      Education degree very well within her Yoga classes, adults
                      &amp; kids alike.
                    </p>
                    <p>
                      Her love for yoga is deep rooted and she is naturally a
                      calm person who finds peace in her practice. Her
                      involvement and conduct with kids in yoga training makes
                      her a sought after &amp; much loved among the little ones.
                    </p>
                    <></>
                  </ReadMoreMulti>

                  <div className='btn_wrapper mb-4'>
                    <Link
                      to='/gallery'
                      className='text-decoration-none read_more_btn'
                    >
                      View Gallery
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='mission_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/trainer_priya.jpg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <figure className='mission_right_shape right_shape mb-0'>
            <img
              src='./assets/images/our_mission_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      {/* OUR VISION SECTION */}
      <section className='vision_section gray-section'>
        <div className='container'>
          <div className='vision_box'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='vision_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/trainer_raunaq.jpg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='vision_content'>
                  <h2>Raunaq Arora</h2>
                  <h5>Master of Arts(Yoga) M.A Yoga / YTTC 200</h5>

                  <ReadMoreMulti slice={250}>
                    <p>
                      A certified Level 3 Yoga Trainer from Ministry of Ayush,
                      Government of India, she started her practice soon after
                      her Masters in Arts degree in 2021. The meditating town
                      for the sages &amp; saints as it is popularly known,
                      Rishikesh, has been her home for the past two years.
                      Raunaq specializes in Hatha &amp; Vinyasa forms of Yoga
                      and is a well- regarded trainer even by her international
                      clientele.
                    </p>
                    <p>
                      Raunaq hails from Delhi, India. She is a big-time foodie
                      who also loves Indie cinema. She loves exploring new
                      cultures and is quick to make friends with likeminded
                      people from across the globe.
                    </p>
                    <p>
                      Her love for Yoga also comes from her Certification in
                      Medical Yoga Therapy and her belief that Yoga practice can
                      be useful in healing ailments such as Cervical, Lumbar
                      Spondylosis and knee Osteoarthritis.
                    </p>
                  </ReadMoreMulti>
                  <div className='btn_wrapper mb-4'>
                    <Link
                      to='/gallery'
                      className='text-decoration-none read_more_btn'
                    >
                      View Gallery
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figure className='vision_left_shape left_shape mb-0'>
            <img
              src='./assets/images/our_vision_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
      {/* OUR MISSION SECTION */}
      <section className='mission_section '>
        <div className='container'>
          <div className='mission_box'>
            <div className='row'>
              <div
                className='col-lg-6 col-md-6 col-sm-12 col-xs-12'
                data-aos='fade-up'
              >
                <div className='mission_content'>
                  <h2>Pritilata Das</h2>
                  <h5>M. Sc in Yoga </h5>
                  <ReadMoreMulti slice={250}>
                    <p>
                      Pritilata holds a Master’ of Science (M. Sc.) degree in
                      Yoga and Bachelor of Science (B. Sc.) degree in physical
                      education from West Bengal, India.
                    </p>
                    <p>
                      Pritilata has many feathers in her cap. Before coming into
                      Yoga Sadhana, she was a sportsperson at heart and
                      represented West Bengal in Badminton and Javelin Throw
                      across various inter-state and intercollege competitions.
                      She has gathered a diverse teaching experience of 7 years
                      ranging from places like Rajasthan, Mumbai, her hometown
                      Kolkata, and even overseas in Vietnam.
                    </p>
                    <p>
                      Her expertise lies in therapy and helping students achieve
                      advanced asanas and flexibility.
                    </p>
                    <p>
                      Priti loves bike riding and any sports activity she can
                      get her hands on. Yet, she believes that Yoga has given
                      her a positive mindset and a can-do attitude that no other
                      sports can.
                    </p>
                    <></>
                  </ReadMoreMulti>

                  <div className='btn_wrapper mb-4'>
                    <Link
                      to='/gallery'
                      className='text-decoration-none read_more_btn'
                    >
                      View Gallery
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                <div className='mission_image'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/trainer_pritilata.jpg'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <figure className='mission_right_shape right_shape mb-0'>
            <img
              src='./assets/images/our_mission_shape.png'
              alt=''
              className='img-fluid'
            />
          </figure>
        </div>
      </section>
    </>
  );
};

export default Trainers;
