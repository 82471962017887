import { Box, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type propsType = {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  title: string;
  description: string;
};
const SessionDesc = ({
  open,
  handleOpen,
  handleClose,
  description,
  title,
}: propsType) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h4' component='h4'>
          {title}
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          {description}
        </Typography>
      </Box>
    </Modal>
  );
};

export default SessionDesc;
