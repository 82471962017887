import React from 'react';
import NavbarMenu from '../../Components/NavbarMenu';
import GenericSubBanner from '../../Components/GenericSubBanner';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>
      <div className='sub-banner-section'>
        <header>
          <NavbarMenu />
        </header>
        {/* SUB BANNER SECTION */}
        <GenericSubBanner
          title='Page not found'
          subtitle='Lost in the digital realm, but the path to inner peace is still within reach.'
          pageName='404'
        />
      </div>

      {/* DISCOUNT SECTION */}
      <section className='discount_section '>
        <div className='container'>
          <div className='row' data-aos='fade-up'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='discount_content'>
                <h2>
                  You're <span>lost</span> exploring relive
                </h2>
                <img
                  src='./assets/images/404.jpg'
                  alt=''
                  className='img-fluid'
                />
                {/* <p className='mt-5 align-start'>
                  Oops! It seems like you've landed on a tranquil and peaceful
                  page that is currently lost in the realm of the unknown. The
                  energy flow of this digital space has taken an unexpected
                  turn, and we apologize for any inconvenience caused.
                </p>
                <p className='align-start'>
                  In our quest for inner harmony and serenity, we strive to
                  maintain balance in all aspects of life, including our
                  website. Unfortunately, the page you were searching for has
                  eluded our digital yoga mats.
                </p>
                <p className='align-start'>
                  But fret not! Just like in yoga, where we learn to adapt and
                  find stillness within, we encourage you to explore the other
                  parts of our website, where you'll discover a multitude of
                  enlightening resources, empowering classes, and transformative
                  experiences that await you.
                </p>
                <p className='align-start'>
                  Remember, yoga is a journey, and sometimes detours and
                  unexpected surprises can lead to extraordinary discoveries. So
                  take a deep breath, release any disappointment, and allow the
                  universe to guide you to an alternative path of discovery.
                </p>
                <p className='align-start'>
                  If you're seeking specific information or have any questions
                  about our yoga center, our dedicated team is always here to
                  assist you. Please don't hesitate to reach out to us through
                  our contact page or give us a call.
                </p>
                <p className='align-start'>
                  Thank you for your understanding, and may your journey towards
                  wellness, balance, and self-discovery continue with grace and
                  ease.
                </p> */}
                {/* <p className='align-start'>Namaste.</p> */}
                <div className='btn_wrapper'>
                  <Link to='/' className='text-decoration-none get_started'>
                    Go Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
