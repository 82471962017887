/* eslint-disable import/order */
import React, { useState } from 'react';
import { images, CustomImage } from './images';
import NavbarMenu from '../../Components/NavbarMenu';
import GenericSubBanner from '../../Components/GenericSubBanner';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Gallery } from 'react-grid-gallery';

const slides = images.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}));

const ReliveGallery = () => {
  const [index, setIndex] = useState(-1);

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  return (
    <>
      <div className='sub-banner-section'>
        <header>
          <NavbarMenu />
        </header>
        {/* SUB BANNER SECTION */}
        <GenericSubBanner
          title='Gallery'
          subtitle='Captivating moments of harmony and mindfulness in our yoga gallery'
          pageName='gallery'
        />
      </div>
      <section className='discount_section '>
        <div className='container'>
          <div className='row' data-aos='fade-up'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
              />
              <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
              />
            </div>
          </div>
        </div>
      </section>
      <div></div>
    </>
  );
};

export default ReliveGallery;
