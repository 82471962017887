import { Suspense } from 'react';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import About from './pages/About';
import NotFound from './pages/NotFound';
import ComingSoon from './pages/ComingSoon';
import ContactUs from './pages/Contact';
import Trainers from './pages/Trainers';
import Sessions from './pages/Sessions';
import ReliveGallery from './pages/Gallery';
import YogaQuestions from './pages/YogaQuestions';
import YogaPoses from './pages/YogaPoses';
// import { NonAuth, RequireAuth, useAuth } from './provider/authProvider';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/coming-soon' element={<ComingSoon />}></Route>
      <Route path='/about-us' element={<About />}></Route>
      <Route path='/contact-us' element={<ContactUs />}></Route>
      <Route path='/our-trainers' element={<Trainers />}></Route>
      <Route path='/sessions' element={<Sessions />}></Route>
      <Route path='/gallery' element={<ReliveGallery />}></Route>
      {/* <Route element={<NonAuth />}></Route>
      <Route element={<RequireAuth />}></Route> */}
      <Route path='/10-yoga-questions' element={<YogaQuestions />} />
      <Route path='/20-yoga-poses' element={<YogaPoses />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
