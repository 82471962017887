import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className='footer-section-alt' id='footer_section'>
      <div className='container'>
        <div className='middle-portion'>
          <div className='row'>
            {/* <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-lg-block d-none'>
              <div className='footer_logo'>
                <Link to='/' className='text-decoration-none'>
                  <figure className='mb-0'>
                    <img
                      src='./assets/images/relive_logo_top_bottom_white_sm.png'
                      alt=''
                      className='img-fluid'
                    />
                  </figure>
                </Link>
              </div>
            </div> */}
            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
              <div className='about_col'>
                <h4>About Us</h4>
                <ul className='list-unstyled'>
                  <li>
                    <p>
                      ReLive is a new age Yoga Studio exclusively for women and
                      kids in Dubai. The ambience of the center and the Yoga
                      sessions are designed keeping a Healthy and Happy you in
                      mind.
                    </p>
                  </li>
                  <li className='icons'>
                    <a
                      href='https://www.facebook.com/yogarelive'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i
                        className='fa-brands fa-facebook-f'
                        aria-hidden='true'
                      />
                    </a>
                  </li>
                  <li className='icons'>
                    <a
                      href='https://twitter.com/ReliveYog'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fa-brands fa-twitter' aria-hidden='true' />
                    </a>
                  </li>
                  <li className='icons'>
                    <a
                      href='https://www.instagram.com/reliveyog/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i
                        className='fa-brands fa-instagram mr-0'
                        aria-hidden='true'
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 d-md-block d-none'>
              <div className='links_col'>
                <h4>Quick Links</h4>
                <ul className='list-unstyled'>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>
                    <Link to='about-us'>About Us</Link>
                  </li>
                  <li>
                    <Link to='/our-trainers'>Our Trainers</Link>
                  </li>
                  <li>
                    <Link to='/sessions'>Sessions</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-5 mt-sm-0 d-sm-block'>
              <div className='contact_col'>
                <h4>Contact Info</h4>
                <ul className='list-unstyled'>
                  <li className='contact_icons'>
                    <i className='fa-solid fa-phone' />
                    <a
                      href='tel:+971555352235'
                      className='text-decoration-none'
                    >
                      +971 55 535 2235
                    </a>
                  </li>
                  <li className='contact_icons'>
                    <i className='fa-solid fa-phone' />
                    <a
                      href='tel:+971509561612'
                      className='text-decoration-none'
                    >
                      +971 50 956 1612
                    </a>
                  </li>
                  <li className='contact_icons'>
                    <i className='fa-sharp fa-solid fa-envelope' />
                    <a
                      href='mailto:namaste@relive.yoga'
                      className='text-decoration-none'
                    >
                      namaste@relive.yoga
                    </a>
                  </li>
                  <li className='mb-0'>
                    <i className='fa-solid fa-location-dot location' />
                    <span>M05 - Waves Residence Al Mankhool, Dubai, UAE</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-portion'>
        <div className='copyright col-xl-12'>
          <p>Copyright 2022, Relive.yoga All Rights Reserved.</p>
        </div>
      </div>
      <div className='footer_shape right_shape'>
        <figure className='mb-0'>
          <img
            src='./assets/images/get_in_touch_shape.png'
            alt=''
            className='img-fluid'
          />
        </figure>
      </div>
    </section>
  );
};

export default Footer;
